import React from 'react'

const Darken = () => {
    return (
        <div className='darken'>
            
        </div>
    )
}

export default Darken
